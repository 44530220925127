import { useState, useEffect } from "react";
import { slide as Menu } from "react-burger-menu";
import { ethers, providers, utils } from "ethers";
import { addy, abi } from "../cfg";
import Web3 from "web3";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import behind from "../images/bts.png";
import home from "../images/home.png";
import about from "../images/about.png";
import gallery from "../images/gallery.png";
import plus from "../images/plus.png";
import minus from "../images/minus.png";
import mintl from "../images/mint_now.png";
import minte from "../images/mint.png";
import keccak256 from "keccak256";
import { tierOneMerkleTree, tierTwoMerkleTree } from "../merkleTree";

function Mint() {
  const [account, setAccount] = useState();
  const [amount, setAmount] = useState(1);
  const [web3, setWeb3] = useState();
  const [claimable, setClaimable] = useState(2);
  const [entitledMints, setEntitledMints] = useState();
  const [stateism, setStateism] = useState("presale");
  const [onWhitelist, setOnWhitelist] = useState(false);
  const [price, setPrice] = useState(0.2);

  const PRICE = 0.2;
  const maxClaimableVIPMint = 2;
  const maxClaimablePresaleMint = 1;

  const test3 = new Web3(
    "https://mainnet.infura.io/v3/25b42d9a0ca744b998cf20d065843177"
  );

  let contract = new test3.eth.Contract(abi, addy);

  const providerOptions = {
    injected: {
      package: null,
    },
    walletconnect: {
      package: WalletConnectProvider,
      network: "ethereum",
      options: {
        infuraId: "25b42d9a0ca744b998cf20d065843177", // required
      },
    },
  };

  const web3Modal = new Web3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions, // required
  });

  async function isWhitelisted() {
    let tierOneMerkleProof = tierOneMerkleTree.getHexProof(keccak256(account));
    let tierTwoMerkleProof = tierTwoMerkleTree.getHexProof(keccak256(account));

    return tierOneMerkleProof.length === 0 || tierTwoMerkleProof.length === 0;
  }

  function getMerkleProof() {
    let tierOneMerkleProof = tierOneMerkleTree.getHexProof(keccak256(account));
    let tierTwoMerkleProof = tierTwoMerkleTree.getHexProof(keccak256(account));

    if (tierOneMerkleProof.length !== 0) {
      return tierOneMerkleProof;
    } else if (tierTwoMerkleProof.length !== 0) {
      return tierTwoMerkleProof;
    }
  }

  function entitled() {
    let tierOneMerkleProof = tierOneMerkleTree.getHexProof(keccak256(account));
    let tierTwoMerkleProof = tierTwoMerkleTree.getHexProof(keccak256(account));

    if (tierOneMerkleProof.length !== 0) {
      return 1;
    } else if (tierTwoMerkleProof.length !== 0) {
      return 2;
    } else {
      return null;
    }
  }

  async function connect() {
    try {
      let provider;
      provider = await web3Modal.connect();
      //let web3 = new providers.Web3Provider(provider);
      let web3 = new Web3(provider);
      setWeb3(web3);

      //const signer = provider.getSigner;
      //console.log(signer);
      //setAccount(signer);
      web3.eth.getAccounts().then(async (addr) => {
        setAccount(addr[0]);
        let tess = getMerkleProof();
        console.log(tess);
        if (isWhitelisted()) {
          setOnWhitelist(true);
        }
      });
    } catch (e) {
      console.log(e);
      return;
    }
  }

  async function increaseCount() {
    if (entitled() > 2) {
      if (amount !== maxClaimablePresaleMint) {
        setAmount(amount + 1);
        setPrice(PRICE * (amount + 1));
      }
    } else if (entitled() > 2) {
      if (amount !== maxClaimableVIPMint) {
        setAmount(amount + 1);
        setPrice(PRICE * (amount + 1));
      }
    }
  }

  async function decreaseCount() {
    if (amount > 1) {
      setAmount(amount - 1);
      setPrice(price - PRICE);
    }
  }

  async function mint() {
    const mintable_price = price.toFixed(2).toString();
    console.log("function fires");
    const tx = {
      from: account,
      to: addy,
      value: ethers.utils.parseEther(mintable_price)["_hex"],
      data: contract.methods
        .mint(amount, getMerkleProof(), entitled())
        .encodeABI(),
    };

    const createTransaction = await web3.eth.sendTransaction(tx);
    /*
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [tx],
    });
    */
  }

  async function publicMint() {
    const mintable_price = price.toFixed(2).toString();
    const tx = {
      from: account,
      to: addy,
      value: ethers.utils.parseEther(mintable_price)["_hex"],
      data: contract.methods.publicMint(amount).encodeABI(),
    };

    const createTransaction = await web3.eth.sendTransaction(tx);
  }

  function entitledText() {
    let tierOneMerkleProof = tierOneMerkleTree.getHexProof(keccak256(account));
    let tierTwoMerkleProof = tierTwoMerkleTree.getHexProof(keccak256(account));

    if (tierOneMerkleProof.length !== 0) {
      setEntitledMints("You can mint a maximum of 1 Clay Friends.");
    } else if (tierTwoMerkleProof.length !== 0) {
      setEntitledMints("You can mint a maximum of 2 Clay Friends.");
    } else {
      setEntitledMints("You are not whitelisted.");
    }
  }

  useEffect(() => {
    entitled();
    entitledText();
  });

  function showMintState() {
    const mintState = "soldout"; //paused, minting, soldOut

    if (mintState == "paused") {
      return (
        <a
          href="#"
          className="lg:w-1/6 mt-7 bg-clay border text-white text-2xl font-bold py-2 px-4 rounded"
        >
          Mint coming soon!
        </a>
      );
    } else if (mintState == "minting") {
      return (
        <>
          <>
            <h2 className="text-4xl text-clay">{amount}</h2>

            <div className="flex">
              <button
                data-action="decrement"
                onClick={decreaseCount}
                class="h-full w-20 rounded-l cursor-pointer outline-none"
              >
                <img src="https://astra.imgix.net/minus.png?w=80" alt="" />
              </button>
              <button
                data-action="increment"
                onClick={increaseCount}
                class="h-full w-20 rounded-r cursor-pointer"
              >
                <img src="https://astra.imgix.net/plus.png?w=80"></img>
              </button>
            </div>
            <button
              className="text-center mt-2 flex justify-center"
              onClick={publicMint}
            >
              <img width="30%" src={mintl} />
            </button>
          </>
        </>
      );
    } else if (mintState == "soldout") {
      return (
        <a
          href="#"
          className="lg:w-1/6 mt-7 bg-clay border text-white text-2xl font-bold py-2 px-4 rounded"
        >
          Sold
        </a>
      );
    }
  }

  return (
    <div>
      <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
        <a href="/" id="home" className="menu-item mb-16">
          <div className="flex items-center justify-center">
            <img src={home} width="40%" className=""></img>
          </div>
        </a>
        <a href="/about" id="about" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={about} width="50%" className=""></img>
          </div>
        </a>
        <a href="#" id="mint" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={minte} width="40%" className=""></img>
          </div>
        </a>
        <a href="/gallery" id="gallery" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={gallery} width="60%" className=""></img>
          </div>
        </a>
        <a href="/bts" id="behind-the-scenes" className="menu-item">
          <img src={behind}></img>
        </a>
      </Menu>

      <div className="relative bg-joel-suger flex w-full mb-2 items-center justify-center py-10 drop-shadow-2xl">
        <img src={mint} width="15%" className="mint-banner-img" />
      </div>

      <div className="w-full mt-12 text-center flex flex-col justify-center items-center mx-auto">
        <img
          src="https://astra.imgix.net/sneakpeeks/Prereveal.gif"
          width="20%"
          className="mint-imge mb-12 rounded p-3 bg-clay"
        />
        <h2 className=" text-clay-text text-shadow text-5xl title-font font-medium mb-4 tracking-widest text-center">
          Mint Clay Friends
        </h2>
        {account && [showMintState()]}{" "}
        {!account && (
          <a
            href="#"
            className=" lg:w-1/6 mt-7 bg-clay border text-white text-2xl font-bold py-2 px-4 rounded"
          >
            Sold out!
          </a>
        )}
      </div>
      <footer className="bg-clay text-center lg:text-left mt-8">
        <div className="text-white text-center p-4">
          © 2022 Copyright:
          <a className="text-white ml-2" href="https://clayfriends.io/">
            Clayfriends
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Mint;
