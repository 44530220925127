import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';

//genesis
let tierOneAddresses = []


//OG
let tierTwoAddresses = []

const tierOneLeafNodes = tierOneAddresses.map(addr => keccak256(addr));
const tierOneMerkleTree = new MerkleTree(tierOneLeafNodes, keccak256, { sortPairs: true });

const tierTwoLeafNodes = tierTwoAddresses.map(addr => keccak256(addr));
const tierTwoMerkleTree = new MerkleTree(tierTwoLeafNodes, keccak256, { sortPairs: true });


export {
    tierOneMerkleTree,
    tierTwoMerkleTree,
};