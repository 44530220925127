import './css/Main.scss';
import logo from './images/cf.png'
import abt from './images/about_us.png'
import newmint from "./images/mintclayfriends.png"
import behind from "./images/bts.png";
import home from "./images/home.png";
import about from "./images/about.png";
import gallery from "./images/gallery.png";
import mint from "./images/mint.png";
import {slide as Menu} from 'react-burger-menu'
import { Link } from 'react-router-dom';

function App() {
  return (
    <div>
      <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
        <a href="/" id="home" className="menu-item mb-16">
          <div className="flex items-center justify-center">
            <img src={home} width="40%" className=""></img>
          </div>
        </a>
        <a href="/about" id="about" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={about} width="50%" className=""></img>
          </div>
        </a>
        <a href='/mint' id="mint" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={mint} width="40%" className=""></img>
          </div>
        </a>
        <a href='/gallery' id="gallery" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={gallery} width="60%" className=""></img>
          </div>
        </a>
        <a href="/bts" id="behind-the-scenes" className="menu-item">
          <img src={behind}></img>
        </a>
      </Menu>
      <div className="App justify-center items-center flex" id='outer-container'>
        
        <div className='clay-flex' id="page-wrap">
          <img src={logo}  className="Logo"></img>
          <Link to='/mint' className='clay-flex__button pt-5'>
            <img src={abt} height="30px"/>
          </Link>
        </div>

      </div>
    </div>
  );
}

export default App;
