import { slide as Menu } from "react-burger-menu";
import behind from "../images/bts.png";
import home from "../images/home.png";
import about from "../images/about.png";
import gallery from "../images/gallery.png";
import mint from "../images/mint.png";

function Bts() {
  return (
    <div>
      <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
        <a href="/" id="home" className="menu-item mb-16">
          <div className="flex items-center justify-center">
            <img src={home} width="40%" className=""></img>
          </div>
        </a>
        <a href="/about" id="about" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={about} width="50%" className=""></img>
          </div>
        </a>
        <a href="/mint" id="mint" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={mint} width="40%" className=""></img>
          </div>
        </a>
        <a href="/gallery" id="gallery" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={gallery} width="60%" className=""></img>
          </div>
        </a>
        <a href="/bts" id="behind-the-scenes" className="menu-item">
          <img src={behind}></img>
        </a>
      </Menu>

      <div className="text-center">
        <div className="relative bg-joel-suger mb-7 flex w-full items-center justify-center py-10 drop-shadow-2xl">
          <img src={behind} width="25%" className="bts-banner-img" />
        </div>
        <div className="flex item-center justify-center">
          <iframe
            src="https://www.youtube.com/embed/f0ArN_MySCo?autoplay=1"
            className="w-2/3 aspect-video rounded-md drop-shadow-xl shadow-xl"
            frameborder="1"
          ></iframe>
        </div>
      </div>
      <footer className="bg-clay text-center lg:text-left mt-8">
        <div className="text-white text-center p-4">
          © 2022 Copyright:
          <a className="text-white ml-2" href="https://clayfriends.io/">
            Clayfriends
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Bts;
