import { slide as Menu } from "react-burger-menu";
import about from "../images/about.png";
import TeamCard from "../components/test";
import skelly from "../images/skelly.png";
import trent from "../images/developers/trent.jpg";
import merc from "../images/developers/merc.jpg";
import lozius from "../images/developers/nisse.jpg.png";
import reks from "../images/developers/reks.png";
import amarok from "../images/developers/eldaniel.jpg.png";
import leon from "../images/developers/leon.jpg";
import poke from "../images/developers/pokekook.gif";
import rek from "../images/developers/rek.jpg";

import behind from "../images/bts.png";
import home from "../images/home.png";
import gallery from "../images/gallery.png";
import mint from "../images/mint.png";

function About() {
  return (
    <>
      <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
        <a href="/" id="home" className="menu-item mb-16">
          <div className="flex items-center justify-center">
            <img src={home} width="40%" className=""></img>
          </div>
        </a>
        <a href="/about" id="about" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={about} width="50%" className=""></img>
          </div>
        </a>
        <a href="/mint" id="mint" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={mint} width="40%" className=""></img>
          </div>
        </a>
        <a href="/gallery" id="gallery" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={gallery} width="60%" className=""></img>
          </div>
        </a>
        <a href="/bts" id="behind-the-scenes" className="menu-item">
          <img src={behind}></img>
        </a>
      </Menu>
      <div className="relative bg-joel-suger flex w-full mb-10 items-center justify-center py-10 drop-shadow-2xl">
        <img src={about} width="25%" className="about-banner-img" />
      </div>

      <div className="container mx-auto px-8 mt-4">
        <div className="container overflow-hidden mb-10">
          <div className="lg:w-4/5 flex mx-auto flex-wrap">
            <div className="lg:w-1/2 w-full p-8 mb-6 lg:mb-0">
              <div className="mt-6">
                <h2 className="text-clay-text text-shadow text-4xl title-font font-medium mb-4 tracking-widest text-center">
                  About the project
                </h2>
                <p className="leading-relaxed mb-4">
                  World renown stop-motion animator Trent Shy is pleased to
                  present Clay Friends, a 5000 piece generative collection made
                  from 100% real clay. Blending meticulously hand-crafted works
                  of art with a one-of-a-kind community experience, Clay Friends
                  represents the unity of web3 and world class artistry.
                </p>
              </div>
              <div className="mt-12">
                <h2 className="text-clay-text text-shadow text-4xl title-font font-medium mb-4 tracking-widest text-center">
                  About the Artist
                </h2>
                <p className="leading-relaxed mb-4">
                  Hailing from Charleston, South Carolina, Trent Shy is a
                  self-taught stop-motion animator widely considered a leading
                  expert in his art form. Trent has a loyal following on social
                  media platforms with almost 1 million followers and over 250
                  million views across Tik-Tok, YouTube, Instagram, and Twitter.
                  Having worked with notable brands such as New Balance, MTV,
                  Bleacher Report, Trent is no stranger to corporate
                  partnerships and how to navigate them. In his free time, Trent
                  is passionate about spending time with his wife Liz and
                  hitting the trails on his mountain bike.
                </p>
              </div>
            </div>
            <img
              className="lg:w-1/2 w-full md:h-auto lg:h-1/2 mt-12 object-cover object-center rounded"
              src="https://astra.imgix.net/skelly.png?w=800"
              alt=""
            />
          </div>
        </div>

        <div className="container py-12 mx-auto space-y-9">
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="text-clay-text text-shadow leading-relaxed text-white text-4xl lg:text-5xl">
              Our Team
            </h2>
          </div>

          <div className="team-container">
            <div className="flex flex-wrap box-border team1">
              <TeamCard
                avatar="https://astra.imgix.net/developers/trent.jpg?w=400"
                name="Trent Shy"
                role="Creator & Artist"
                text="Trent Shy is the creator of Clay Friends. He is an artist and stop motion animator that specializes in clay. He started animating clay on his kitchen floor and now operates as a professional one-man studio out of Charleston, SC. His animations have been viewed over 250 million times on YouTube and he's also worked for large companies like MTV and New Balance. He is now dedicating the rest of his career to Clay Friends and the clay world that they live in."
              />
              <TeamCard
                avatar="https://astra.imgix.net/developers/nisse.jpg.png?w=400"
                name="Nils Garland"
                role="Lead Engineer"
                text="CTO at AstraLabs. Coder, Niner, Trent Shy fan. Talk is cheap, show me the code."
              />
              <TeamCard
                avatar="https://astra.imgix.net/developers/eldaniel.jpg.png?w=400"
                name="Daniel Vazquez"
                role="Advisor"
                text="CEO at AstraLabs. Traveler from the past that came here during NFT and Crypto Takeover!"
              />
            </div>
          </div>
        </div>
        <div className="team-container mb-8">
          <div className="flex flex-wrap box-border team1">
            <TeamCard
              avatar={merc}
              name="Mercedhees"
              role="Moderator/Partner"
              text="Boomer, Gymorexic, and Trent's #1 simp!"
            />
            <TeamCard
              avatar="https://astra.imgix.net/developers/leon.jpg?w=400"
              name="Leon Fortgens"
              role="Moderator"
              text="Passionate 19 year old working as a project manager at AstraLabs."
            />
            <TeamCard
              avatar="https://astra.imgix.net/developers/rek.jpg?w=400"
              name="Rexx Larsson"
              role="Engineer"
              text="Code lover, cat appreciator, and avid NFT fan. Astralabs lead engineer aiming for the stars!"
            />
          </div>
        </div>
      </div>
      <footer className="bg-clay text-center lg:text-left mt-8">
        <div className="text-white text-center p-4">
          © 2022 Copyright:
          <a className="text-white ml-2" href="https://clayfriends.io/">
            Clayfriends
          </a>
        </div>
      </footer>
    </>
  );
}

export default About;
