import { slide as Menu } from "react-burger-menu";
import behind from "../images/bts.png";
import home from "../images/home.png";
import about from "../images/about.png";
import gallery from "../images/gallery.png";
import mint from "../images/mint.png";
import cf1 from "../images/sneakpeeks/cf1.jpg";
import cf2 from "../images/sneakpeeks/cf2.jpg";
import cf3 from "../images/sneakpeeks/cf3.jpg";
import cf4 from "../images/sneakpeeks/cf4.jpg";
import cf5 from "../images/sneakpeeks/cf5.jpg";
import cf6 from "../images/sneakpeeks/cf6.jpg";

function Gallery() {
  return (
    <div>
      <Menu right pageWrapId={"page-wrap"} outerContainerId={"outer-container"}>
        <a href="/" id="home" className="menu-item mb-16">
          <div className="flex items-center justify-center">
            <img src={home} width="40%" className=""></img>
          </div>
        </a>
        <a href="/about" id="about" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={about} width="50%" className=""></img>
          </div>
        </a>
        <a href="/mint" id="mint" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={mint} width="40%" className=""></img>
          </div>
        </a>
        <a href="/gallery" id="gallery" className="menu-item mb-16">
          <div className=" flex items-center justify-center">
            <img src={gallery} width="60%" className=""></img>
          </div>
        </a>
        <a href="/bts" id="behind-the-scenes" className="menu-item">
          <img src={behind}></img>
        </a>
      </Menu>

      <div className="relative bg-joel-suger flex w-full mb-10 items-center justify-center py-10 drop-shadow-2xl">
        <img src={gallery} width="25%" className="gallery-banner-img" />
      </div>

      <div className="container mx-auto p-7 lg:p-20 xl:p-20">
        <div className="grid-cols-3 p-3 bg-clay space-y-2 lg:space-y-0 lg:grid lg:gap-3 lg:grid-rows-3">
          <div className="w-full rounded">
            <img
              src="https://astra.imgix.net/sneakpeeks/cf3.jpg?w=688"
              alt="image"
            />
          </div>
          <div className="w-full col-span-2 row-span-2 rounded">
            <img
              src="https://astra.imgix.net/sneakpeeks/cf1.jpg?w=800"
              alt="image"
            />
          </div>
          <div className="w-full rounded">
            <img
              src="https://astra.imgix.net/sneakpeeks/cf2.jpg?w=688"
              alt="image"
            />
          </div>
          <div className="w-full rounded">
            <img
              src="https://astra.imgix.net/sneakpeeks/cf4.jpg?w=688"
              alt="image"
            />
          </div>
          <div className="w-full rounded">
            <img
              src="https://astra.imgix.net/sneakpeeks/cf5.jpg?w=688"
              alt="image"
            />
          </div>
          <div className="w-full rounded">
            <img
              src="https://astra.imgix.net/sneakpeeks/cf6.jpg?w=688"
              alt="image"
            />
          </div>
        </div>
      </div>
      <footer className="bg-clay text-center lg:text-left mt-8">
        <div className="text-white text-center p-4">
          © 2022 Copyright:
          <a className="text-white ml-2" href="https://clayfriends.io/">
            Clayfriends
          </a>
        </div>
      </footer>
    </div>
  );
}

export default Gallery;
