import { useRef } from "react";
import Flippy, { FrontSide, BackSide } from "react-flippy";

export default function TeamCard({ ...props }) {
  const ref = useRef();
  return (
    <Flippy
      flipOnClick={true}
      flipDirection="horizontal"
      ref={ref}
      // style={{ width: "242px", height: "364px" }}
      className="rounded-lg h-96"
    >
      <FrontSide
        style={{ backgroundColor: "#4574da" }}
        className="rounded-lg h-96"
      >
        <div className="h-full flex flex-col items-center text-center">
          <img
            className="flex-shrink-0 rounded-lg w-full h-56 object-cover object-center aspect-square h-64"
            src={props.avatar ? props.avatar : "https://dummyimage.com/200x200"}
            alt="team"
          />
          <div className="w-full pt-4">
            <h2 className="title-font font-medium text-lg text-white">
              {props.name}
            </h2>
            <h3 className="text-lg text-gray-200 mb-1">{props.role}</h3>
          </div>
        </div>
      </FrontSide>
      <BackSide style={{ backgroundColor: "#4574da" }} className="rounded-lg">
        <div className="h-full flex flex-col items-center text-center -mx-2">
          <div className="w-full overflow-y-auto text-scroll">
            <h2 className="title-font font-medium text-md text-white">
              {props.text}
            </h2>
            <span className="inline-flex"></span>
          </div>
        </div>
      </BackSide>
    </Flippy>
  );
}
